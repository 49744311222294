<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="vendor-profile-container row">
      <div class="d-flex top-container">
        <div class="vendor-image col-1"></div>
        <div class="col-md-11 align-self-center">
          <div class="vendor-name">
            <router-link :to="{name: 'SingleVendor', params: {id: vendorId}}">
              {{ name }}
            </router-link>
          </div>
          <div class="registration-date">{{ $t('registered-on') }}: {{ date }}</div>
        </div>
      </div>
      <div class="bottom-container">
        <p class="vendor-paragraph"> {{ description }}</p>
        <Button :size="'medium'" :theme="'solid-grey'" :text="$t('show-profile')" @click="$router.push({name: 'SingleVendor', params: {id: vendorId}})"/>
      </div>
    </div>
  </div>
</template>

